import { DeepPartial } from '@empiriecom/module-components/utils/interfaces';
import deepmerge from '@/src/utils/deepmergeconfig';
import { tenantConfig } from '@empiriecom/mybuy-components/config/universalat';
import defaultConfig from './default';
import { FragmentConfig } from './types';

const universalatconfig: DeepPartial<FragmentConfig> = {
  ...tenantConfig,
  locales: ['de-AT'],
  l10n: {
    'de-AT': ['base-de', 'universalat-de'],
  },
  companyId: 'universalat',
  tracking: {
    gtmId: 'GTM-PZZ54R2',
    soastaId: 'VCRLH-5E9R4-TT9EZ-675H5-A9TNA',
  },
  permissions: {
    newsletterEnabled: false,
    eInvoiceEnabled: false,
  },
  show: {
    ...tenantConfig.show,
    ibanBeforeBic: true,
  },
  overview: {
    newsBadge: {
      color: 'secondary',
    },
  },
  personalData: {
    supressEmailChange: true,
  },
  lounge: {
    banner: (language: string, device: string) =>
      `https://bilder.universal.at/empiriecom/io/universalat/${language}/lounge_banner_${device}.jpg`,
    premiumContentSubscription: (language: string) =>
      `https://fragment-content-external.universalat.empirie.cloud/mein-konto/premium-subscription-${language}`,
    premiumContentSalesTarget: (language: string) =>
      `https://fragment-content-external.universalat.empirie.cloud/mein-konto/premium-salestarget-${language}`,
    premiumContentFixedTerm: (language: string) =>
      `https://fragment-content-external.universalat.empirie.cloud/mein-konto/premium-fixedterm-${language}`,
    premiumContentDummy: (language: string) =>
      `https://fragment-content-external.universalat.empirie.cloud/mein-konto/premium-dummy-${language}`,
    switchModalButtons: true,
    premiumOnTop: true,
  },
  images: {
    logoSmall: {
      default: 'https://bilder.baur.de/i/empiriecom/universal_logo_small.png',
    },
  },
  joe: {
    enabled: true,
  },
  flexikonto: {
    enabled: true,
    externalUrls: {
      faq: 'https://fragment-content-external.universalat.empirie.cloud/de/mein-konto/mein-flexikonto',
    },
  },
  checkMarkColor: 'secondary',
};

export default deepmerge(defaultConfig, universalatconfig) as FragmentConfig;
